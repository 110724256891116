import { APP_INITIALIZER, NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { hotJarInit } from '../core/utils/hotjar';
import { ClientConfigService } from '../core/services/client-config.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule.forRoot(
      [
        {
          path: 'id_token',
          redirectTo: 'service'
        },
        {
          path: 'access_token',
          redirectTo: 'service'
        },
        {
          path: 'service',
          data: {
            breadcrumb: 'QIAsphere(Service)',
            serviceMode: true
          },
          children: [
            { path: 'unauthorized', redirectTo: 'error/unauthorized', pathMatch: 'full' },
            { path: 'forbidden', redirectTo: 'error/forbidden', pathMatch: 'full' },
            {
              path: '',
              loadChildren: () => import('../qiasphere.module').then(m => m.QiasphereModule)
            },
            { path: '', redirectTo: 'qiasphere', pathMatch: 'full' }
          ]
        },
        {
          path: '',
          loadChildren: () => import('../qiasphere.module').then(m => m.QiasphereModule),
          data: {
            breadcrumb: 'QIAsphere'
          }
        }
      ],
      {
    initialNavigation: 'enabledBlocking',
    useHash: false,
    onSameUrlNavigation: 'reload',
    enableTracing: false
}
    ),
    NgxWebstorageModule.forRoot({ prefix: 'qia', separator: '-' })
  ],
  providers: [
    ClientConfigService,
    { provide: APP_INITIALIZER, useFactory: hotJarInit, deps: [ClientConfigService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
